/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Icon, { TYPE_CHECKMARK } from '../../../daily/daily-components/Icon/Icon'
import { FC_BLUE, FC_DARK_BLUE } from '../../../emotionVariables'
import { WeeklySessionGoalOptions } from '../../../../functions/shared/constants'
import { SegmentProvider, useSendSegmentEvent } from '../../../wrappers/SegmentProvider'
import { Text } from '../../flowComponents'

export const WeeklyGoal = ({ activeOption, setActiveOption }) => 
  <SegmentProvider baseData={{ goalOptions: Object.keys(WeeklySessionGoalOptions) }} eventLabel='Weekly Goal'>
    <WeeklyGoalContent activeOption={activeOption} setActiveOption={setActiveOption} />
  </SegmentProvider>

const WeeklyGoalContent = ({ activeOption, setActiveOption }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const goalOptionSelected = (optionKey) => {
    setActiveOption(optionKey)
    sendSegmentEvent("Setting Selected", { optionKey })
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      gap: 8px;
    `}>
      {Object.entries(WeeklySessionGoalOptions).map(([optionKey, optionValues]) =>
        <GoalOption 
          key={optionKey}
          option={optionValues} 
          selected={activeOption === optionKey} 
          select={() => goalOptionSelected(optionKey)} 
        />
      )}
    </div>
  )
}

const GoalOption = ({ option, selected, select }) => {
  const { name, hours } = option
  return (
    <div onClick={select} css={css`
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      padding: 16px;
      border: 1px solid ${selected ? FC_DARK_BLUE : '#E0E0E0'};
      transition: all 0.2s ease;
      
      &:hover {
        border-color: ${FC_DARK_BLUE};
        background: ${selected ? 'transparent' : '#F8F9FA'};
      }
    `}>
      <div css={css`
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid ${selected ? FC_BLUE : '#C4C4C4'};
        ${selected && css`
          background-color: ${FC_BLUE};
        `}

        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-right: 16px;
        transition: all 0.2s ease;
      `}>
        {selected && <Icon css={css`fill: white; width: 10px; height: 10px;`} type={TYPE_CHECKMARK}/>}
      </div>
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
      `}>
        <Text css={css`
          color: #404040;
          font-weight: 500;
          letter-spacing: 0.5px;
        `}>{name}</Text>
        <Text css={css`
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
        `}>{`${hours} hours a week`}</Text>
      </div>
    </div>
  )
}
