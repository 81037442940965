/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { ONE_SECOND } from '../../functions/shared/constants';
import { useGetMostRecentParticipantGoalsForUser, useUpdateSessionGoals, useUpdateSessionTitle } from '../fetch/endpoints';
import { useFirestoreSubscribe } from '../firebase/helpers';
import { UserContext } from '../UserProvider';
import { useDebounced, useDelay } from '../utils';
import { LoadingIndicator, Text } from './flowComponents';
import { useGoals, makeNewGoalObject } from './GoalList/useGoals';
import { GoalListWithCopyPaste } from './GoalList/GoalListWithCopyPaste';
import { useAutomaticallyFetch } from '../fetch/helpers';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { WeeklySpecificGoalToggle, useWeeklySpecificGoal } from './WeeklySpecificGoal';

const MAX_TITLE_LENGTH = 40;

export const CalendarGoalEntry = ({ eventId, initialCalendarTitle, saveGoalsRef }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [titleText, setTitleText] = useState(initialCalendarTitle || "")

  const [participants] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('participants')
        .where('eventId', '==', eventId)
        .where('userId', '==', user.uid),
    fields: ["personalInviteEventId", "emailInviteId", "start"]
  })
  const [participant] = participants
  const googleCalendarEventCreated = participant !== undefined && participant.personalInviteEventId !== null
  const eventCreated = participant !== undefined && (participant.personalInviteEventId !== null || participant.emailInviteId !== null)
  const assumeCalendarEventCreationFailed = useDelay(7 * ONE_SECOND) && !eventCreated
  const isCurrentWeek = participant !== undefined && dayjs(participant.start.toDate()).isSame(dayjs(), 'week')

  const goalsState = useState([makeNewGoalObject([])] )
  const [goals, setGoals] = goalsState
  const goalsData = useGoals(goalsState)
  const { weeklySpecificGoal, toggleSpecificGoal, weeklySpecificGoalSaved } = useWeeklySpecificGoal({
    eventId,
    initialWeeklySpecificGoal: null,
    goals: goalsData.goals
  })

  const { performFetch: updateParticipantGoal, error: updateGoalError } = useUpdateSessionGoals()

  const { result: lastSessionData } = useAutomaticallyFetch(useGetMostRecentParticipantGoalsForUser)

  useEffect(() => {
    if (participant !== undefined) {
      saveGoalsDebounced()
      if (saveGoalsRef)
        saveGoalsRef.current = saveGoals;
    }
  }, [goalsData.goals])


  const saveGoals = async () => {
    const { success } = await updateParticipantGoal({ eventId, goals: goalsData.goals, updateCalendarEvent: true, weeklySpecificGoal })
    if (success) {
      sendSegmentEvent('Added Goals After Booking', {
        goals
      })
    }
  }
  const [saveGoalsDebounced] = useDebounced(saveGoals, 1000, true)

  const { performFetch: updateParticipantTitle, error: updateTitleError } = useUpdateSessionTitle()
  const saveTitle = async (title) => {
    const { success } = await updateParticipantTitle({ eventId, title })
    if (success) {
      sendSegmentEvent('Added Title After Booking', {
        title,
      })
    }
  }
  const [saveTitleDebounced] = useDebounced(saveTitle, 1000, true)

  useEffect(() => {
    if (googleCalendarEventCreated && initialCalendarTitle !== undefined && initialCalendarTitle !== '') {
      saveTitle(initialCalendarTitle.slice(0, MAX_TITLE_LENGTH))
    }
  }, [googleCalendarEventCreated])

  const titleTextChanged = (event) => {
    if (event.target.value.length > MAX_TITLE_LENGTH) {
      return;
    }
    setTitleText(event.target.value);
    saveTitleDebounced(event.target.value);
  }

  useEffect(() => {
    if (weeklySpecificGoal && !titleText && googleCalendarEventCreated) {
      const newTitle = weeklySpecificGoal.length >= MAX_TITLE_LENGTH
        ? weeklySpecificGoal.slice(0, MAX_TITLE_LENGTH - 3) + '...'
        : weeklySpecificGoal;
      setTitleText(newTitle);
      saveTitleDebounced(newTitle);
    }
  }, [weeklySpecificGoal, googleCalendarEventCreated]);


  const titleStyle = {
    width: "100%",
    border: "none",
    resize: "none",
    height: "28px",
    float: "left",
    display: "inline-block",
    flex: 1,
  }
  const titleFormatting = {
    float: "left",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Red Hat Display",
    letterSpacing: "0.5px",
  };
  const inputBoxFormatting = {
    width: "100%",
    padding: "12px 16px 2px 16px",
    border: "1px solid #C4C4C4",
    borderRadius: "8px",
    float: "left",
    display: "flex",
    background: "#ffffff",
  };
  const titleInputUlStyle = {
    listStyleType: "none",
    padding: 0,
    marginBottom: 8,
  };
  const maxCharactersStyle = {
    paddingTop: "4px",
    fontSize: "12px",
    float: "none",
    width: "100%",
    textAlign: "right",
  };
  if (titleText.length === MAX_TITLE_LENGTH) {
    maxCharactersStyle.color = "red";
  }

  const error = updateGoalError || updateTitleError || null

  if (assumeCalendarEventCreationFailed) {
    return (
      <Text>We seem to be having some trouble creating your calendar event — your Flow Club session was successfully booked, but it may not correctly appear in your personal calendar.</Text>
    )
  }

  if (!eventCreated) {
    return (
      <div>
        <Text>Creating calendar event...</Text>
        <LoadingIndicator/>
      </div>
    )
  }

  return (
    <div>
      {googleCalendarEventCreated &&
      <>
      <div>
        <div style={titleFormatting}>
          Calendar event title
        </div>
        <div style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "14px", float: "left" }}>&nbsp; Optional</div>
      </div>
      <br />
      <div style={{ margin: "0px 0px 16px 0px", }}>
        <div className="title-input-box" style={inputBoxFormatting}>
          <ul id="first" style={titleInputUlStyle}>
            <li style={{ opacity: "50%", float: "left" }}>🌊&nbsp;</li>
          </ul>
          <ul id="second" style={{ ...titleInputUlStyle, flex: 1 }}>
            <li style={{ flex: 1 }}><textarea
              className="title-input"
              type="text"
              placeholder="Prep for meeting, Write article draft, Plan day, etc"
              style={titleStyle}
              value={titleText}
              onChange={titleTextChanged}
            /></li>
          </ul>
          {/* only add this type hint if the person is putting in their own title */}

        </div>
        {titleText &&<div style={{ ...titleInputUlStyle, float: "left", color: "rgba(0, 0, 0, 0.5)" }}>@ Flow Club</div>}
        <div style={maxCharactersStyle}>
          {titleText.length}/{MAX_TITLE_LENGTH}
        </div>
      </div>
      </>}
      <div>
        <div style={titleFormatting}>
          Goals
        </div>
        <div style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "14px", float: "left" }}>&nbsp; Optional</div>
      </div>
      <br />
      {weeklySpecificGoalSaved && isCurrentWeek && (
        <WeeklySpecificGoalToggle
          weeklySpecificGoal={weeklySpecificGoal}
          weeklySpecificGoalSaved={weeklySpecificGoalSaved}
          onToggle={toggleSpecificGoal}
        />
      )}
      <GoalListWithCopyPaste goalsData={goalsData} lastSessionData={lastSessionData} />
      {error && <Alert variant="danger" css={css` color: white;`}>
        {error}
      </Alert>}
    </div>
  )
}