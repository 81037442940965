/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useState } from 'react'
import { useGetPostFirstFlowInfo, useGetPostFlowInfo, useGetPostFlowMilestoneInfo } from '../fetch/endpoints'
import { useAutomaticallyFetch } from '../fetch/helpers'
import { UserContext } from '../UserProvider'
import { MainLoading } from '../commonLayout/MainLoading'
import { Link, Redirect, useParams } from 'react-router-dom'
import { arrObjectsToObject, getUserIsUnauthed } from '../../functions/shared/helpers'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { MilestoneDisplay } from './MilestoneDisplay'
import { PostSessionSignupPrompt } from './PostSessionSignupPrompt'
import { useFirestoreSubscribe } from '../firebase/helpers'
import { PostSessionFeedbackScreen } from './PostSessionFeedbackScreen'
import { ContinueButtons, SingleContinueButton } from './ContinueButtons'
import { AnimatedSlidingWrapper } from '../components/AnimatedSlidingWrapper'
import { RecommendedSession } from './RecommendedSession'
import { CreateSessionPrompt } from './CreateSessionPrompt'
import { useQuery } from '../utils'

export const PostSession = () => {
  const { user } = useContext(UserContext)
  const { sessionId: eventId } = useParams();
  const query = useQuery();
  const error = query.get('error');
  const sendSegmentEvent = useSendSegmentEvent()

  // beautiful code happening right here
  const { result: postFlowInfo } = useAutomaticallyFetch(useGetPostFlowInfo, { currentEventId: eventId }, { dependencies: [user] })
  const { result: postFirstFlowInfo } = useAutomaticallyFetch(useGetPostFirstFlowInfo, { eventId }, { transform: result => ({ ...result, recommendationType: result.recommendationType ?? "subsequentDay-legacy" }) })
  useEffect(() => { if (postFirstFlowInfo !== null && postFlowInfo !== null) { sendSegmentEvent("Post Session Next-Session Recommendation Loaded", {
    recommendationType: postFirstFlowInfo.recommendationType,
    sessionRecommended: postFirstFlowInfo.recommendedSession !== null,
    nextUpcomingParticipantExists: postFlowInfo.nextUpcomingParticipant !== null
  }) } }, [postFirstFlowInfo, postFlowInfo])
  const { result: postFlowMilestoneInfo } = useAutomaticallyFetch(useGetPostFlowMilestoneInfo, { currentEventId: eventId })

  const [participantsById, participantsLoading] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('participants')
        .where('eventId', '==', eventId)
        .where('status', '!=', 'canceled'),
    fields: ["userId", "flowScore", "siteFeedback", "hostFeedback", "goals", "weeklySpecificGoal"],
    transform: participants => arrObjectsToObject(participants, "userId")
  })
  const noValidParticipantFound = user === null || (!participantsLoading && participantsById[user.uid] === undefined)

  if (noValidParticipantFound) {
    return <Redirect to={{ pathname: `/session/${eventId}` }} />
  }

  if (postFlowInfo === null || postFirstFlowInfo === null || postFlowMilestoneInfo === null || participantsLoading) {
    return <MainLoading />
  }

  return (
    <SegmentProvider eventLabel={"Post Session"}>
      <div css={css`
        background-color: white;
        display: flex;
        justify-content: center;
        padding-top: 60px;
        text-align: center;
      `}>
        <PostSessionContent participant={participantsById[user.uid]} eventId={eventId} postFlowInfo={postFlowInfo} postFirstFlowInfo={postFirstFlowInfo} postFlowMilestoneInfo={postFlowMilestoneInfo} error={error} />
      </div>
    </SegmentProvider>
  )
}

const PostSessionContent = ({ participant, eventId, postFlowInfo, postFirstFlowInfo, postFlowMilestoneInfo, error }) => {
  const { user } = useContext(UserContext)
  const { nextUpcomingParticipant, spaces, canCreateSessions, eventAttendanceRun, totalSessions } = postFlowInfo

  const { recommendedSession, recommendationType } = postFirstFlowInfo
  const space = spaces[0] !== undefined ? spaces[0] : null

  const [milestoneDisplayExited, setMilestoneDisplayExited] = useState(false)
  const [continuedToPostFeedbackScreen, setContinuedToPostFeedbackScreen] = useState(false)

  const isFirstSession = totalSessions + (eventAttendanceRun ? 0 : 1) === 1
  const showCreateSessionPrompt = canCreateSessions && isFirstSession

  const showPostFeedbackScreen =
    (recommendedSession !== null && nextUpcomingParticipant === null) ||
    showCreateSessionPrompt

  const PostSessionScreens = [
    {
      Content:
        <div css={css`padding: 0px 20px;`}>
          <PostSessionSignupPrompt canCreateSessions={canCreateSessions} isFirstSession={isFirstSession} />
        </div>,
      Footer: <ContinueButtons nextUpcomingParticipant={nextUpcomingParticipant} space={space} />
    },
    {
      Content:
        <div css={css`padding: 0px 20px;`}>
          <MilestoneDisplay postFlowMilestoneInfo={postFlowMilestoneInfo} />
        </div>,
      Footer: <SingleContinueButton onClick={() => setMilestoneDisplayExited(true)} />
    },
    {
      Content:
        <PostSessionFeedbackScreen eventId={eventId} postFlowInfo={postFlowInfo} participant={participant} error={error} />,
      Footer: (showPostFeedbackScreen) ?
        <SingleContinueButton onClick={() => setContinuedToPostFeedbackScreen(true)} /> :
        <ContinueButtons nextUpcomingParticipant={nextUpcomingParticipant} space={space} />
    },
    {
      Content:
        <div css={css`padding: 0px 20px;`}>
          <RecommendedSession session={recommendedSession} recommendationType={recommendationType} />
        </div>,
      Footer: <ContinueButtons nextUpcomingParticipant={nextUpcomingParticipant} space={space} />
    },
    {
      Content:
        <div css={css`padding: 0px 20px;`}>
          <CreateSessionPrompt />
        </div>,
      Footer: <Link to={"/upcoming/create/"}>
        <SingleContinueButton text={"Create A Session"} />
      </Link>
    },
  ]

  const userIsUnauthed = getUserIsUnauthed(user)
  const { isMilestone } = postFlowMilestoneInfo
  
  const activeScreenIndex = userIsUnauthed ? 0 :
    (isMilestone && !milestoneDisplayExited) ? 1 :
    (!showPostFeedbackScreen || !continuedToPostFeedbackScreen) ? 2 :
    (showCreateSessionPrompt ? 4 : 3)
  
  // reset scroll when moving between post-session screens, so that scrolling down on one doesn't make the subsequent one start scrolled way below its content
  useEffect(() => {
    document.scrollingElement.scrollTo(0, 0)
  }, [activeScreenIndex])

  const sendSegmentEvent = useSendSegmentEvent()
  useEffect(() => {
    sendSegmentEvent("Step Viewed", {
      activeScreenIndex,
      showPostFeedbackScreen,
      showCreateSessionPrompt,
      isFirstSession,
      recommendedSessionExists: recommendedSession !== null,
      totalSessions,
      eventAttendanceRun,
      canCreateSessions
    })
  }, [activeScreenIndex])

  return (
    <>
      <AnimatedSlidingWrapper currentItemIndex={activeScreenIndex} skipAnimatingHeight>
        {PostSessionScreens.map(({ Content }) => Content)}
      </AnimatedSlidingWrapper>
      {PostSessionScreens[activeScreenIndex].Footer}
    </>
  )
}