/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { useUpdateSessionGoals } from '../fetch/endpoints'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { toastSuccess } from './utils/toaster'
import { Text, ToggleSwitchButton } from './flowComponents'
import { usePreferences } from '../UserProvider'

export const useWeeklySpecificGoal = ({ eventId, initialWeeklySpecificGoal = null, goals }) => {
  const preferences = usePreferences()
  const { weeklySpecificGoal: weeklySpecificGoalSaved } = preferences
  const [weeklySpecificGoal, setWeeklySpecificGoal] = useState(initialWeeklySpecificGoal)
  const { performFetch: updateParticipantGoal } = useUpdateSessionGoals()
  const sendSegmentEvent = useSendSegmentEvent()

  const toggleSpecificGoal = async (selected) => {
    const newGoal = selected ? weeklySpecificGoalSaved : null
    setWeeklySpecificGoal(newGoal)
    
    await updateParticipantGoal({ 
      eventId, 
      goals,
      updateCalendarEvent: true, 
      weeklySpecificGoal: newGoal 
    })

    sendSegmentEvent('Toggled Weekly Goal', {
      weeklySpecificGoal: selected,
    })

    toastSuccess({ message: `This session ${selected ? ' will' : ' will not'} count towards your weekly goal.` })
  }

  return {
    weeklySpecificGoal,
    weeklySpecificGoalSaved,
    toggleSpecificGoal
  }
}

export const WeeklySpecificGoalToggle = ({ weeklySpecificGoal, weeklySpecificGoalSaved, onToggle }) => (
  <div css={css`
    display: flex; 
    justify-content: space-between; 
    align-items: flex-start;
    text-align: left; 
    margin-bottom: 12px;
    gap: 16px;
    max-width: 420px;
  `}>
    <Text css={css`
      flex: 1;
      min-width: 0;
      word-wrap: break-word;
    `}>
      Count towards <b>{weeklySpecificGoalSaved}</b>
    </Text>
    <ToggleSwitchButton
      css={css`flex-shrink: 0;`}
      selected={!!weeklySpecificGoal}
      setSelected={onToggle}
    />
  </div>
)