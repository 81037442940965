/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation
} from "react-router-dom"

import {
  Row,
} from 'react-bootstrap'


import AccountPreferences from './account/account';
import UpdateProfile from './update-profile/update-profile';
import SubscriptionAndBilling from './subscription-and-billing/subscription-and-billing'
import InviteFriend from './invite-friend/invite-friend'
import Icon, { TYPE_CALENDAR, TYPE_SETTINGS_ACCOUNT, TYPE_SETTINGS_GOALS, TYPE_SETTINGS_HOSTING, TYPE_SETTINGS_MEMBERSHIP, TYPE_SETTINGS_PROFILE, TYPE_SETTINGS_REFERRALS, TYPE_STAR_IN_CIRCLE } from '../../daily/daily-components/Icon/Icon';
import { useMediaQuery } from 'react-responsive';
import { SegmentProvider } from '../../wrappers/SegmentProvider';
import { ClubsAndHostsSettings } from './ClubsAndHostsSettings';
import { GoalSettings } from './goal-settings/GoalSettings';
import { SessionSettings } from './SessionSettings';
import { HostingSettings } from './HostingSettings';
import { useHostIncentiveProgress } from '../../HostIncentiveProgress';
import { useContext, useMemo } from 'react';
import { UserContext } from '../../UserProvider';
import { getUserIsHost } from '../../../functions/shared/helpers';

const SettingsNav = ({ settingsSubRoutes }) => {
  let { url } = useRouteMatch()
  const location = useLocation()
  const subRoutePath = location.pathname.slice(url.length)
  return (
    <div css={css`
      width: 196px;
      margin-top: 40px;
      flex-shrink: 0;
    `}>
      {settingsSubRoutes.map(({ route, icon, name }) => {
        const active = route.includes(subRoutePath)
        return (
          <Link
            css={css`
              height: 48px;
              display: flex;
              align-items: center;
              letter-spacing: 0.5px;
              border-radius: 0px 2px 2px 0px;
              color: rgba(0, 0, 0, .87);
              background-color: ${active && '#EAF3FF'};
              font-weight: ${active && 'bold'};
              &:hover {
                background-color: ${!active && '#EBEDF0'};
                text-decoration: none;
              }
            `}
            to={`${url}${route}`}
          >
            {/* @ts-ignore */}
            <Icon css={css`
              margin-left: 20px;
              margin-right: 16px;
              width: 16px;
              height: 16px;
              fill: ${active ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)'};
            `} type={icon} />{name}
          </Link>
        )
      })}
    </div>
  )
}

const Settings = () => {
  const { user } = useContext(UserContext)
  let { url } = useRouteMatch()
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })

  const hostProgressData = useHostIncentiveProgress()

  const userIsHost = getUserIsHost(user)

  const settingsSubRoutes = useMemo(() => [
    { route: '/account/', icon: TYPE_SETTINGS_ACCOUNT, name: 'Account', Component: AccountPreferences },
    { route: '/profile/', icon: TYPE_SETTINGS_PROFILE, name: 'Profile', Component: UpdateProfile },
    { route: '/goals/', icon: TYPE_SETTINGS_GOALS, name: 'Goals', Component: GoalSettings },
    { route: '/sessions/', icon: TYPE_CALENDAR, name: 'Sessions',
      Component: SessionSettings },
    { 'route': '/clubs-and-hosts/', icon: TYPE_STAR_IN_CIRCLE, name: 'Clubs and Hosts', Component: ClubsAndHostsSettings },
    { route: '/billing/', icon: TYPE_SETTINGS_MEMBERSHIP, name: 'Membership', Component: SubscriptionAndBilling },
    { route: '/invite/', icon: TYPE_SETTINGS_REFERRALS, name: 'Referrals', Component: InviteFriend },
    ...(userIsHost ? [{
      route: '/hosting/', icon: TYPE_SETTINGS_HOSTING, name: 'Hosting', Component: () => <HostingSettings progressData={hostProgressData} />
    }] : [])
  ], [userIsHost, hostProgressData])

  return (
    <SegmentProvider eventLabel={'Settings'}>
      <div css={css`
        display: flex;
        flex-flow: ${mobileView ? "column" : "row"};
      `}>
        <SettingsNav settingsSubRoutes={settingsSubRoutes} />
        <div className="container-settings" css={css`width: 100%;`}>
          <Row css={css`
            display: flex;
            justify-content: flex-start;
            margin-left: ${!mobileView ? 'calc(33% - 196px)' : '16px'};
          `}>
            <div css={css`
              max-width: ${mobileView ? '90%' : '600px'};
            `}>
              <Switch>
                {settingsSubRoutes.map(({ route, Component, name }) =>
                  <Route key={name} path={`${url}${route}`}>
                    <Component />
                  </Route>
                )}
                {/* covering legacy links */}
                <Route path={`${url}/weekly-goal`}>
                  <Redirect to={`/settings/goals/`} />
                </Route>
                <Route path={`${url}/`}>
                  <Redirect to={`/settings/account/`} />
                </Route>
              </Switch>
            </div>
          </Row>
        </div>
      </div>
    </SegmentProvider>
  )
}

export default Settings