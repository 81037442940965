import { reorder, useDebounced, usePrevious } from '../../utils';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { v4 as uuidv4 } from 'uuid';

const GOAL_SUGGESTIONS = [
  "Warmup for work",
  "Take 3 deep breaths",
  "Relax the shoulders",
  "Clear your workspace",
  "Take a comfortable seat, or stance",
  "Do the easy thing first",
  "Review today's priorities",
  "Book a Flow Club session"
]

export const makeNewGoalObject = (existingGoals, newGoalData = null) => {
  // arbitrary id, just used for react keying -- not stored serverside
  const id = uuidv4()
  
  if (newGoalData !== null) {
    return {
      text: newGoalData.text,
      placeholder: null,
      completedAt: newGoalData.completed ? Math.floor(Date.now() / 1000) : null,
      id
    }
  }

  const unusedGoals = GOAL_SUGGESTIONS.filter(goal => !existingGoals.some(({ text, placeholder }) => text === goal || placeholder === goal))
  return {
    text: existingGoals.length === 0 ?
      unusedGoals[Math.floor(Math.random() * unusedGoals.length)] :
      '',
    placeholder: existingGoals.length > 0 ?
      unusedGoals[Math.floor(Math.random() * unusedGoals.length)] :
      null,
    completedAt: null,
    id
  }
} 

export const useGoals = (goalsState, onGoalCompleted = () => {}, moveDoneToTop = true) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const [goals, setGoals] = goalsState
  const currentGoal = goals.find(goal => !goal.completedAt)

  const addGoal = () => {
    setGoals(goals => [...goals, makeNewGoalObject(goals)])
    sendSegmentEvent("Goal Added")
  }

  const pasteGoals = (newGoals, prefilled = false) => {
    setGoals(goals => [...goals, ...newGoals.map(goal => makeNewGoalObject(goals, goal))])
    sendSegmentEvent("Goals Bulk Pasted", { prefilled })
  }

  const replaceGoals = (newGoals) => {
    setGoals(newGoals.map(goal => ({ ...goal, completed: goal.completedAt !== null })))
  }

  const goalEdited = (text, goalId) => {
    setGoals(goals.map(goal =>
      goal.id === goalId
        ? { ...goal, text }
        : goal
    ));
  }

  const toggleCompleted = (goalId) => {
    const goal = goals.find(g => g.id === goalId);
    if (!goal) return;

    const newCompletedAt = goal.completedAt ? null : Math.floor(Date.now() / 1000);

    let updatedGoals;
    if (moveDoneToTop && newCompletedAt) {
      const completedGoals = goals.filter(g => g.completedAt && g.id !== goalId);
      const uncompletedGoals = goals.filter(g => !g.completedAt && g.id !== goalId);
      updatedGoals = [
        ...completedGoals,
        { ...goal, completedAt: newCompletedAt },
        ...uncompletedGoals
      ];
    } else {
      updatedGoals = goals.map(g =>
        g.id === goalId
          ? { ...g, completedAt: newCompletedAt }
          : g
      );
    }

    setGoals(updatedGoals);

    if (!goal.completedAt) {
      sendSegmentEvent("Goal Marked Completed", { text: goal.text });
      onGoalCompleted()
    }
  }

  const removeGoal = (goalId) => {
    setGoals(goals.filter(goal => goal.id !== goalId));
  }

  const reorderGoals = ({ originalIndex, destinationIndex }) => {
    setGoals(goals => reorder(goals, originalIndex, destinationIndex))
  }

  const previousGoals = usePrevious(goals) ?? []
  const justCompletedIndex = goals.findIndex(goal => {
    const previousGoal = previousGoals.find(({ id }) => goal.id === id)
    return goal.completedAt !== null && previousGoal !== undefined && previousGoal.completedAt === null
  })

  return { goals, addGoal, pasteGoals, replaceGoals, goalEdited, toggleCompleted, removeGoal, reorderGoals, justCompletedIndex, currentGoal }
}