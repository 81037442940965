/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { WeeklyGoal } from './WeeklyGoal';
import { useState } from 'react'
import { usePreferences } from '../../../UserProvider'
import { useSetUserPreferences, useGetUserHoursBookedThisWeek } from '../../../fetch/endpoints'
import { useAutomaticallyFetch } from '../../../fetch/helpers';
import { WeeklySessionGoalOptions } from '../../../../functions/shared/constants'
import { toastError, toastSuccess } from '../../utils/toaster'
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES, ToggleSwitchButton } from '../../flowComponents';
import Icon, { TYPE_STAR } from '../../../daily/daily-components/Icon/Icon';
import { WeeklyProgressStatus } from '../../WeeklyGoalProgressIndicator';

const SpecificGoal = ({ goalText, setGoalText, hoursGoal, setHoursGoal }) => {
  return (
    <div css={css`margin-top: 24px;`}>
      <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 12px;`}>
        What's your goal?
      </Text>
      <input 
        value={goalText}
        onChange={(e) => setGoalText(e.target.value)}
        placeholder="Work on thesis, apply to jobs, learn Spanish"
        css={css`
          width: 100%;
          padding: 12px;
          border: 1px solid #E0E0E0;
          border-radius: 8px;
          margin-bottom: 8px;
          font-size: 16px;
          &:focus {
            outline: none;
            border-color: #0066FF;
          }
        `}
      />
      <div css={css`
        display: flex;
        align-items: flex-start;
        gap: 8px;
        padding: 12px;
        background: #F8F9FA;
        border-radius: 8px;
        margin: 8px 0 32px;
      `}>
        <Icon type={TYPE_STAR} css={css`
          width: 16px;
          height: 16px;
          margin-top: 2px;
          fill: rgba(0, 0, 0, 0.6);
        `}/>
        <Text style={TEXT_STYLES.BODY_2} customCss={css`color: rgba(0, 0, 0, 0.6);`}>
          You'll be able to mark whether each session counts towards this goal when booking or at the end of the session. You'll also be able to set a new goal each week.
        </Text>
      </div>
      <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 12px;`}>
        Hours to dedicate to this goal weekly
      </Text>
      <WeeklyGoal 
        activeOption={hoursGoal} 
        setActiveOption={setHoursGoal}
      />
    </div>
  )
}

const useGoalSettings = () => {
  const { weeklyHoursGoal, weeklySpecificGoal: savedSpecificGoal } = usePreferences()
  const [weeklyTotalOption, setWeeklyTotalOption] = useState(weeklyHoursGoal ? 
    Object.keys(WeeklySessionGoalOptions)[Object.values(WeeklySessionGoalOptions).findIndex(({ hours }) => hours === weeklyHoursGoal)] :
    Object.keys(WeeklySessionGoalOptions)[0])
  const [hasSpecificGoal, setHasSpecificGoal] = useState(!!savedSpecificGoal)
  const [specificGoalText, setSpecificGoalText] = useState(savedSpecificGoal || '')
  const { performFetch: setUserPreferences, fetching: loading } = useSetUserPreferences()
  const hoursGoal = WeeklySessionGoalOptions[weeklyTotalOption].hours
  const weeklySpecificGoal = hasSpecificGoal ? specificGoalText : null

  const saveGoals = async () => {
    const { success } = await setUserPreferences({ 
      newPreferenceData: { 
        weeklyHoursGoal: hoursGoal,
        weeklySpecificGoal,
      }
    })
    if (success) {
      toastSuccess({ message: 'Successfully saved your changes!' })
    } else {
      toastError({ message: "There was an error saving your settings. Please try again or contact members@flow.club and we'll get this resolved." })
    }
  }

  const weeklyTotalOptionDirty = weeklyTotalOption !== Object.keys(WeeklySessionGoalOptions)[Object.values(WeeklySessionGoalOptions).findIndex(({ hours }) => hours === weeklyHoursGoal)]
  const specificGoalDirty = specificGoalText !== savedSpecificGoal?.text

  return { 
    weeklyTotalOption, 
    setWeeklyTotalOption,
    hasSpecificGoal,
    setHasSpecificGoal, 
    specificGoalText,
    setSpecificGoalText,
    weeklyTotalOptionDirty,
    specificGoalDirty,
    saveGoals, 
    loading,
    weeklySpecificGoal,
    hoursGoal,
  }
}
export const GoalSettings = ({ }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { 
    weeklyTotalOption, 
    setWeeklyTotalOption,
    hasSpecificGoal,
    setHasSpecificGoal,
    specificGoalText,
    setSpecificGoalText,
    weeklyTotalOptionDirty,
    specificGoalDirty,
    saveGoals, 
    loading,
    weeklySpecificGoal,
    hoursGoal,
  } = useGoalSettings()
  const { result: hoursData, fetching } = useAutomaticallyFetch(useGetUserHoursBookedThisWeek)
  const { hoursBooked, specificGoalHours } = hoursData || {}
  const goalPortionMet = Math.min(1, hoursBooked / hoursGoal)
  const specificGoalPortionMet = weeklySpecificGoal ? Math.min(1, specificGoalHours / hoursGoal) : 0

  const saveButtonClicked = () => {
    saveGoals()
    sendSegmentEvent("Weekly/Monthly Goal Settings Saved", { 
      weeklyTotalOption, 
      weeklyTotalOptionChanged: weeklyTotalOptionDirty,
      specificGoalChanged: specificGoalDirty,
      hasSpecificGoal,
    })
  }

  return (
    <div>
      <Text style={TEXT_STYLES.APP_H1} customCss={css`margin-bottom: 16px;`}>
        Weekly Goal Settings
      </Text>
      {fetching ?
      <LoadingIndicator /> :
      hoursData && hoursGoal ?
      <WeeklyProgressStatus
        goalHours={hoursGoal}
        hoursBooked = {hoursBooked}
        specificGoalHours={specificGoalHours}
        weeklySpecificGoal={weeklySpecificGoal}
        goalPortionMet={weeklySpecificGoal ? specificGoalPortionMet : goalPortionMet}
        showEdit={false}

      />
      : null}
      <Text style={TEXT_STYLES.BODY_1} customCss={css`margin: 24px 0px;`}>
        Setting a weekly goal helps you stay committed. We'll send you reminders to join Flow Clubs.
      </Text>

      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      `}>
        <Text style={TEXT_STYLES.BODY_1}>Track a specific goal?</Text>
        <ToggleSwitchButton 
          selected={hasSpecificGoal} 
          setSelected={(value) => {
            setHasSpecificGoal(value)
            sendSegmentEvent("Specific Goal Toggled", { enabled: value })
          }} 
        />
      </div>
      <Text style={TEXT_STYLES.BODY_2} customCss={css`
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: ${hasSpecificGoal ? '0' : '32px'};
      `}>
        Track hours towards a particular project or objective
      </Text>

      {hasSpecificGoal ? (
        <SpecificGoal
          goalText={specificGoalText}
          setGoalText={setSpecificGoalText}
          hoursGoal={weeklyTotalOption}
          setHoursGoal={setWeeklyTotalOption}
        />
      ) : (
        <WeeklyGoal 
          activeOption={weeklyTotalOption} 
          setActiveOption={setWeeklyTotalOption}
        />
      )}

      <FlowButton 
        onClick={saveButtonClicked} 
        loading={loading} 
        fillAvailableWidth
        customCss={css`
          margin-top: 32px;
          background: #14142B;
          border-radius: 8px;
          height: 48px;
        `}
      >
        Save Changes
      </FlowButton>
    </div>
  )
}
