/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, useEffect, useRef } from 'react';
import { UserContext, usePreSignupUserDataProperty } from '../UserProvider';
import { LoginSignup } from '../auth/LoginSignup';
import { goalToMakeTimeFor } from '../../functions/shared/constants';

export const SignupPane = () => {
  const { goToNextPane } = useContext(InterstitialContext)
  const makingTimeFor = usePreSignupUserDataProperty('makingTimeFor')
  const goal = goalToMakeTimeFor[makingTimeFor]
  const titleOverride = goal ? `Sign up to book sessions for ${goal}` : null
  const { user } = useContext(UserContext)

  // skip pane is user is already logged in
  const skippingPane = useRef(false)
  useEffect(() => {
    if (user !== null && !skippingPane.current) {
      skippingPane.current = true
      goToNextPane()
    }
  }, [user])

  return (
    <div>
      <LoginSignup showLoginModeLink={false} startInLoginMode={false} showBookingRelatedText={false} titleOverride={titleOverride} />
    </div>
  )
}