/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { WeeklyProgressStatus } from '../components/WeeklyGoalProgressIndicator';
import dayjs from 'dayjs';
import { useFirestoreQuery } from '../firebase/helpers';
import { useGetMultipleUsersHoursBookedThisWeek } from '../fetch/endpoints';

export const WeeklyGoalsAdmin = () => {
  const startOfWeek = dayjs().startOf('week').toDate()
  const [userHoursData, setUserHoursData ] = useState([])
  
  const [users, usersLoading ] = useFirestoreQuery({
    functionalQuery: db => db.collection('users')
      .where('preferences.weeklySpecificGoal', '!=', ''),
    fields: ['id', 'displayName', 'emojis', 'preferences']
  })

  const {
    performFetch: fetchHoursData,
    loading: hoursLoading
  } = useGetMultipleUsersHoursBookedThisWeek()

  useEffect(() => {
    const fetchUserGoals = async () => {
      const userIds = users.map(user => user.id)
      const { result: hoursData, success } = await fetchHoursData({ userIds })
      if (success) {
        setUserHoursData(hoursData.results)
      } else {
        setUserHoursData([])
      }
    }
    if (users && !usersLoading) {
      fetchUserGoals()
    }

  }, [users, usersLoading])

  if (usersLoading || hoursLoading || userHoursData.length === 0) {
    return (
      <div css={css`padding: 20px; min-height: 100vh; text-align: center`}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div css={css`padding: 20px; min-height: 100vh; text-align: center`}>
      <Text style={TEXT_STYLES.APP_H1}>Weekly Goals Dashboard</Text>
      <Text style={TEXT_STYLES.H6}>Users with a weekly goal: {users.length} </Text>
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;`}>
      {users.map(user => {
        const userData = userHoursData.find(d => d.userId === user.id)
        const weeklyHoursGoal = user.preferences?.weeklyHoursGoal || 0
        const weeklySpecificGoal = user.preferences?.weeklySpecificGoal
        const goalPortionMet = userData.specificGoalHours / weeklyHoursGoal

        return (
          <div key={user.id} css={css`
            margin: 20px 0;
            width: calc(33.333% - 40px);
            padding: 20px;
            text-align: left;
          `}>
            <Text style={TEXT_STYLES.APP_H2}>{user.displayName} {user.emojis}</Text>
            <WeeklyProgressStatus 
              goalHours={weeklyHoursGoal}
              hoursBooked={userData.hoursBooked}
              specificGoalHours={userData.specificGoalHours}
              weeklySpecificGoal={weeklySpecificGoal}
              goalPortionMet={goalPortionMet}
              showEdit={false}
            />
          </div>
        )
      })}
      </div>
    </div>
  )
}