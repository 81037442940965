/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react';
import { Alert, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LoadingIndicator, Text, FlowButton, BUTTON_STYLES, TEXT_STYLES } from './components/flowComponents';
import { toastError } from './components/utils/toaster';
import { SegmentProvider } from './wrappers/SegmentProvider';
import { GoalListWithCopyPaste } from './components/GoalList/GoalListWithCopyPaste';
import { useGoals, makeNewGoalObject } from './components/GoalList/useGoals';
import { useGetUserGoals, useUpdateUserGoals, useGetMostRecentParticipantGoalsForUser, useSetUserPreferences } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';
import { useDebounced } from './utils';
import { usePreferences } from './UserProvider'
import { v4 as uuidv4 } from 'uuid';

export const Tasks = () => {
  const { hideCompletedTasks: hideCompletedTasksSavedPreference } = usePreferences()
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const goalsState = useState([makeNewGoalObject([])]);
  const [goals, setGoals] = goalsState;
  const goalsData = useGoals(goalsState, () => {}, false);
  const [validateMarkdown, setValidateMarkdown] = useState(() => { return () => true });
  const [hideCompletedTasks, setHideCompletedTasks] = useState(hideCompletedTasksSavedPreference || false)
  const filteredGoalsData = {
    ...goalsData,
    goals: hideCompletedTasks 
      ? goalsData.goals.filter(goal => !goal.completedAt)
      : goalsData.goals
  };
  const completedTasksCount = goalsData.goals?.filter(goal => goal.completedAt).length || 0;
  
  const { performFetch: getUserGoals } = useGetUserGoals();
  const { performFetch: updateUserGoals } = useUpdateUserGoals();
  const { result: lastSessionData } = useAutomaticallyFetch(useGetMostRecentParticipantGoalsForUser)
  const { performFetch: setUserPreferences, fetching: updatingUserPreferences, error: setUserPreferencesError } = useSetUserPreferences()

  useEffect(() => {
    const fetchGoals = async () => {
      setLoading(true)
      const { success, result } = await getUserGoals();
      setLoading(false)
      if (success && result) {
        const goals = result.map(goal => ({
          ...goal,
          id: goal.id || uuidv4()
        }))
        setGoals(goals);
      }
    }
    fetchGoals();
  }, []);

  const saveGoals = async () => {
    if (!validateMarkdown()) {
      return;
    }
    setSaving(true);
    const { success } = await updateUserGoals({ goals: goalsData.goals });
    setSaving(false);
    if (success) {
      setLastSaved(new Date());
    } else {
      toastError({ message: "Failed to save tasks" });
    }
  }

  const [saveGoalsDebounced] = useDebounced(saveGoals, 1000, true);

  useEffect(() => {
    saveGoalsDebounced();
  }, [goalsData.goals]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (saving) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [saving]);

  return (
    <SegmentProvider eventLabel={'My Flow Club'}>
      <div css={css`
        background-color: white;
        min-height: 100vh;
      `}>
        <Helmet>
          <title>Tasks</title>
          <meta name='robots' content='noindex, follow' />
        </Helmet>
        <Container css={css`
          text-align: center;
          margin-top: 20px;
        `}>
          <h1>Tasks</h1>
          {loading ? 
            <LoadingIndicator /> :
            <>
              {completedTasksCount && filteredGoalsData.goals.length === 0 ? (
                <NoTasksStillnessMessage />
              ) : null}
              <GoalListWithCopyPaste goalsData={filteredGoalsData} lastSessionData={lastSessionData} onValidate={setValidateMarkdown} />
              <div css={css`
                margin: 8px 0px;
                height: 24px;
                color: rgba(0, 0, 0, 0.5);
              `}>
                {saving ? (
                  <div css={css`display: flex; align-items: center; justify-content: center; gap: 8px;`}>
                    <LoadingIndicator size="sm" />
                    <Text>Saving...</Text>
                  </div>
                ) : lastSaved && (
                  <Text>Saved your goals</Text>
                )}
              </div>
            </>
          }
          {completedTasksCount ? <div css={css`
            margin: 20px 0;
            display: flex;
            justify-content: flex-end;
          `}>
            <FlowButton
              onClick={async () => {
                const newValue = !hideCompletedTasks;
                setHideCompletedTasks(newValue);
                await setUserPreferences({ newPreferenceData: { hideCompletedTasks: newValue }});
              }}
              buttonStyle={BUTTON_STYLES.OUTLINE_DARK}
              disabled={updatingUserPreferences}
            >
              {hideCompletedTasks ? 'Show' : 'Hide'} {completedTasksCount} completed task{completedTasksCount !== 1 ? 's' : ''}
            </FlowButton>
          </div> : null}

          <Alert variant="info">
            A more fully-featured version of managing your task list is coming soon! For now, you can copy and paste tasks from sessions here and we'll store them for you without them being attached to any sessions so you don't have to carry over long lists everywhere.
        </Alert>
        </Container>
      </div>
    </SegmentProvider>
  );
}

const stillnessMessages = [
  { message: "Zero tasks left, 100% awesomeness achieved. Well done!", emoji: "💯" },
  { message: "You did it! An empty task list. Take a second to celebrate that win, no matter how big or small.", emoji: "🎉" },
  { message: "All done! Take a minute to relax and recharge.", emoji: "🛋️" },
  { message: "You crushed it—everything is checked off?!?", emoji: "✅" },
  { message: "Great work! Nothing left but good vibes.", emoji: "✨" },
  { message: "Empty list, full heart—awesome job!", emoji: "💖" },
  { message: "You've earned a break. Enjoy the calm!", emoji: "🌴" },
  { message: "Every box ticked—you're unstoppable!", emoji: "🔥" },
  { message: "Done and dusted! Celebrate your wins!", emoji: "🥳" },
  { message: "You did it! Do you feel that? That's how success feels.", emoji: "💪🏼" },
  { message: "Nothing left to do but celebrate!", emoji: "🎊" },
  { message: "You've unlocked the *empty list* achievement!", emoji: "🏅" },
  { message: "Mission accomplished. Take a deep breath!", emoji: "🌬️" },
];

const NoTasksStillnessMessage = () => {
  const stillnessMessage = useMemo(() => stillnessMessages[Math.floor(Math.random() * stillnessMessages.length)], []);
  const { message, emoji } = stillnessMessage
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 24px;
        padding: 12px;
      `}
    >
      <Text style={TEXT_STYLES.APP_H5}>
        {message}
      </Text>
      <Text style={TEXT_STYLES.APP_H3}>
        {emoji}
      </Text>
    </div>
  )
}