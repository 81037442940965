/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TabbedInterface } from '../../components/flowComponents'
import { FC_BACKGROUND_GRAY } from '../../emotionVariables'

export const Header = ({ tabs, activeTabIndex, setActiveTabIndex }) => {
  return (
    <div css={css`
      background-color: white;
      box-shadow: 0px 2px 4px #E5E5E5;
    `}>
      <h1 className="schedule-h1 my-4 text-center">Sessions</h1>
      <TabbedInterface
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        customContentCss={css`background-color: ${FC_BACKGROUND_GRAY}; padding: 24px;`}
        customHeaderCss={css`text-align: center; justify-content: center;`}
      />
    </div> 
  )
}