/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs';
import { DarkTooltip } from '../DarkTooltip';
import { Text, TEXT_STYLES, TextIcon } from '../flowComponents';
import { GoalList } from './GoalList';
import { TooltipWrapper } from '../TooltipWrapper';
import Icon, { TYPE_CLIPBOARD, TYPE_CLOCK } from '../../daily/daily-components/Icon/Icon';
import { FC_IRIS_BLUE, FC_LIGHTER_BLUE } from '../../emotionVariables';
import { copyToClipboard } from '../../utils';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { toastError, toastSuccess } from '../utils/toaster';
import { shift, offset } from '@floating-ui/react-dom';

const parsePastedMarkdown = input => ((input || '').match(/((?:- )?\[ ?[x]? ?\]|-) (.*)/gim) ?? []).slice(0, 50) // arbitrarily limit to 50 matched goals so things don't actually break on some insane input
const goalIsCompleted = input => input.match(/\[ ?[x] ?\]/gi) !== null
const strippedGoal = input => input.replace((input.match(/((?:- )?\[ ?[x]? ?\]|-) /gi) ?? [""])[0], "")
const textContainsMarkdownCheckbox = input => input.match(/((?:- )?\[ ?[x]? ?\]|-) /gi) !== null
export const parseGoalsToMarkdown = goals => goals.reduce(
  (goalsString, { completedAt, text }) =>
    `${goalsString ? `${goalsString}\n` : ''}- [${completedAt !== null ? 'X' : ' '}] ${text}`,
  '')
export const validateMarkdownText = (text) => {
  if (!text) return true
  const lines = text.trim().split('\n');
  const nonEmptyLines = lines.filter(line => line.length > 0);
  return nonEmptyLines.every(line => textContainsMarkdownCheckbox(line));
}

export const GoalListWithCopyPaste = ({ goalsData, disabled = false, lastSessionData = null, onValidate = () => true }) => {
  const [isMarkdownMode, setIsMarkdownMode] = useState(false);
  const [markdownText, setMarkdownText] = useState('');
  const [markdownError, setMarkdownError] = useState(null);
  const textareaRef = useRef(null);
  const isMac = navigator.platform.indexOf('Mac') > -1

  const sendSegmentEvent = useSendSegmentEvent()
  const copyToClipboardClicked = async () => {
    copyToClipboard(parseGoalsToMarkdown(goalsData.goals), { onSuccessMessage: "Successfully copied goals to clipboard!" })
    sendSegmentEvent("Copy Goals To Clipboard Clicked")
  }

  const onPaste = (event) => {
    const text = event.clipboardData.getData("text")
    // if clipboard contains something that's not text, this will be an empty string
    // allow normal pasting if this doesn't look like a fancy formatted paste
    if (!textContainsMarkdownCheckbox(text)) {
      return
    }
    processPastedGoals(text)
    event.preventDefault()
    sendSegmentEvent("Pasted Goals via keyboard or menu")
  }

  const pasteFromClick = async () => {
    const text = await navigator.clipboard.readText();
    if (!textContainsMarkdownCheckbox(text)) {
      toastError({ message: "Sorry, we only know how to process markdown-formatted goals." })
      return
    }
    processPastedGoals(text)
    sendSegmentEvent("Pasted goals via click")
  }

  const processPastedGoals = (text) => {
    const pastedGoals = parsePastedMarkdown(text).map(text => ({ originalText: text, text: strippedGoal(text), completedAt: goalIsCompleted(text) ? Math.floor(Date.now() / 1000) : null }))
    goalsData.pasteGoals(pastedGoals)
    toastSuccess({ message: "Pasted goals from your clipboard!" })
  }

  const copyPreviousGoalsClicked = () => {
    if (lastSessionData.goals !== null) {
      if (isMarkdownMode) {
        setMarkdownText(markdownText + '\n' + parseGoalsToMarkdown(lastSessionData.goals))
        goalsData.pasteGoals(lastSessionData.goals)
      } else {
        goalsData.pasteGoals(lastSessionData.goals)
      }
      sendSegmentEvent("Copy Previous Goals Clicked")
      toastSuccess({ message: "Copied over your goals" })
    }
  }

  const toggleMarkdownMode = () => {
    if (isMarkdownMode && textareaRef.current) {
      const text = textareaRef.current.value;
      if (validateMarkdownText(text)) {
        const parsedGoals = parsePastedMarkdown(text).map(text => ({
          text: strippedGoal(text),
          completedAt: goalIsCompleted(text) ? Math.floor(Date.now() / 1000) : null
        }));
        goalsData.replaceGoals(parsedGoals);
      }
      else {
        setMarkdownError('Please use markdown checklist format: "- [ ] task" or "- [x] completed task"');
        return;
      }
    } else {
      setMarkdownText(parseGoalsToMarkdown(goalsData.goals));
      setMarkdownError(null);
    }
    setIsMarkdownMode(!isMarkdownMode);
  }

  const validateAndSaveMarkdown = () => {
    if (!isMarkdownMode) {
      return true;
    }

    const text = textareaRef.current?.value;
    if (!validateMarkdownText(text)) {
      setMarkdownError('Please use markdown checklist format: "- [ ] task" or "- [x] completed task"');
      return false;
    }

    const parsedGoals = parsePastedMarkdown(text).map(text => ({
      text: strippedGoal(text),
      completedAt: goalIsCompleted(text) ? Math.floor(Date.now() / 1000) : null
    }));
    goalsData.replaceGoals(parsedGoals);
    setMarkdownError(null);
    return true;
  }

  useEffect(() => {
    onValidate(() => isMarkdownMode ? validateAndSaveMarkdown : () => true)
  }, [isMarkdownMode, onValidate])

  return (
    <div>
      <div css={css`display: flex; justify-content: flex-end; gap: 24px;`}>
        {lastSessionData !== null && lastSessionData.goals.length > 0 &&
          <div onClick={copyPreviousGoalsClicked} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
          <ChecklistIconTooltip TooltipContents={
            <Text style={TEXT_STYLES.CAPTION}>
              {`Copy over ${lastSessionData.goals.length} goal${lastSessionData.goals.length > 1 ? 's' : ''} from previous session on ${dayjs(lastSessionData.start).format("MM/DD/YY, hh:mm a")}:`}
              <ul css={css`text-align: left;`}>
              {lastSessionData.goals.map(goal => <li key={goal.text}>{goal.text}</li>)}
              </ul>
            </Text>
          }>
            <div>
              <Icon type={TYPE_CLOCK} css={css`fill: ${FC_IRIS_BLUE}; width: 24px; height: 24px;`} />
            </div>
          </ChecklistIconTooltip>
          </div>
        }
        <div onClick={copyToClipboardClicked} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
        <ChecklistIconTooltip TooltipContents={<Text style={TEXT_STYLES.CAPTION}>{'Copy list'}</Text>}>
          <div>
            <Icon type={TYPE_CLIPBOARD} css={css`fill: ${FC_IRIS_BLUE}; width: 24px; height: 24px;`} />
          </div>
        </ChecklistIconTooltip>
        </div>
        <div onClick={pasteFromClick} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
        <ChecklistIconTooltip TooltipContents={
          <Text style={TEXT_STYLES.CAPTION}>
            <div>{'Your Markdown-formatted todos can be pasted directly— just paste into any of the input boxes, e.g.'}</div>
            <div>[ ] uncompleted task</div>
            <div>[ X ] completed task!</div>
          </Text>
        }>
          <TextIcon text={isMac ? '⌘ + V' : 'Ctrl + V'} />
        </ChecklistIconTooltip>
        </div>
        {/* <div onClick={toggleMarkdownMode} css={css`
          cursor: pointer;
          vertical-align: top;
          align-items: top;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
            <ChecklistIconTooltip TooltipContents={
            <Text style={TEXT_STYLES.CAPTION}>
              {isMarkdownMode ? 'Switch to Checklist' : 'Switch to Markdown'}
            </Text>
          }>
            <TextIcon text={isMarkdownMode ? 'List' : 'MD'} />
          </ChecklistIconTooltip>
        </div> */}
      </div>
      <div css={css`
        font-size: initial;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0px;
      `}>
        {isMarkdownMode ? (
          <div css={css`width: 100%;`}>
            <textarea
              ref={textareaRef}
              value={markdownText}
              onChange={(e) => {
                const text = e.target.value;
                setMarkdownText(text)
                if (validateMarkdownText(text)) {
                  const parsedGoals = parsePastedMarkdown(text).map(text => ({
                    text: strippedGoal(text),
                    completedAt: goalIsCompleted(text) ? Math.floor(Date.now() / 1000) : null
                  }));
                  goalsData.replaceGoals(parsedGoals);
                  setMarkdownError(null);
                } else {
                  setMarkdownError('Please use markdown checklist format: "- [ ] task" or "- [x] completed task"');
                }
              }}
              css={css`
                width: 100%;
                padding: 12px;
                margin: 24px 0;
                border: 1px solid ${markdownError ? 'red' : 'inherit'};
                line-height: 1.5;
                min-height: 3em;
                height: ${Math.min(10, (markdownText.match(/\n/g) || []).length + 3) * 1.5}em
              `}
              defaultValue={markdownText}
            />
            {markdownError && (
              <Text css={css`color: red;`}>{markdownError}</Text>
            )}
          </div>
        ) : (
          <GoalList onPaste={onPaste} goalsData={goalsData} interactionEnabled={!disabled} />
        )}
      </div>
    </div>
  )
}

export const ChecklistIconTooltip = ({ children, TooltipContents }) =>
  <TooltipWrapper TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'bottom', middleware: [shift({ padding: 0 }), offset(12)] }} TooltipContents={TooltipContents} retainMousedOverInsideTooltip={true}>
    {children}
  </TooltipWrapper>

