/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FC_BLUE, FC_DARK_BLUE, FC_GOLD, FC_GREEN } from '../emotionVariables'
import { useGetUserHoursBookedThisWeek } from '../fetch/endpoints'
import { useAutomaticallyFetch } from '../fetch/helpers'
import { usePreferences } from '../UserProvider'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { DarkText } from './flowComponents'
import { TooltipWrapper } from './TooltipWrapper'

const INCOMPLETE_COLOR = FC_BLUE
const COMPLETE_COLOR = FC_GREEN
const SPECIFIC_GOAL_COLOR = FC_GOLD

export const WeeklyGoalProgressIndicator = () => {
  const { weeklyHoursGoal, weeklySpecificGoal } = usePreferences()
  const { result: hoursData, fetching } = useAutomaticallyFetch(useGetUserHoursBookedThisWeek)

  if (weeklyHoursGoal === undefined || weeklyHoursGoal === 0 || fetching || !hoursData) {
    return null
  }

  const { hoursBooked, specificGoalHours } = hoursData
  const goalPortionMet = Math.min(1, hoursBooked / weeklyHoursGoal)
  const flooredHoursBooked = (weeklySpecificGoal ? specificGoalHours : hoursBooked).toFixed(1)
  const specificGoalPortionMet = weeklySpecificGoal ? Math.min(1, specificGoalHours / weeklyHoursGoal) : 0

  return (
    <SegmentProvider baseData={{ hoursBooked, weeklyHoursGoal, specificGoalHours }} eventLabel={'Weekly Goal Progress Indicator'}>
      <div css={css`margin-right: 16px;`}>
        <TooltipWrapper 
          TooltipComponent={WeeklyProgressTooltip} 
          TooltipContents={
            <WeeklyProgressStatus
              goalHours={weeklyHoursGoal} 
              hoursBooked={hoursBooked}
              specificGoalHours={specificGoalHours}
              weeklySpecificGoal={weeklySpecificGoal} 
              goalPortionMet={weeklySpecificGoal ? specificGoalPortionMet : goalPortionMet}
            />
          } 
          useFloatingArgs={{ placement: 'bottom-end' }}
        >
          <ProgressCircle 
            portionFilled={weeklySpecificGoal ? specificGoalPortionMet : goalPortionMet}
            color={weeklySpecificGoal ? SPECIFIC_GOAL_COLOR : COMPLETE_COLOR}
          >
            {flooredHoursBooked}
          </ProgressCircle>
        </TooltipWrapper>
      </div>
    </SegmentProvider>
  )
}

export const WeeklyProgressTooltip = ({ children }) => {
  return (
    <div css={css`
      padding: 24px 36px;
      background: white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    `}>
      {children}
    </div>
  )
}

export const WeeklyProgressStatus = ({ goalHours, hoursBooked, specificGoalHours, weeklySpecificGoal, goalPortionMet, showEdit = true }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const editGoalLinkClicked = () => {
    sendSegmentEvent("Edit Goal Link Clicked")
  }
  
  const goalMet = goalPortionMet >= 1
  console.log('goalPortionMet', goalPortionMet)
  const progressColor = weeklySpecificGoal ? 
    (goalMet ? COMPLETE_COLOR : SPECIFIC_GOAL_COLOR) :
    (goalMet ? COMPLETE_COLOR : INCOMPLETE_COLOR)

  const hoursBookedTotal = hoursBooked.toFixed(1)
  const hoursBookedTowardsGoal = (weeklySpecificGoal ? specificGoalHours.toFixed(1) : hoursBookedTotal)


  const targetHours = goalHours

  return (
    <div>
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}>
        <DarkText customCss={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 20px;
        `}>
          Weekly Progress
        </DarkText>
        {showEdit &&
        <Link to="/settings/goals" onClick={editGoalLinkClicked}>
          <div css={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: ${FC_DARK_BLUE};
          `}>
            Edit Goal
          </div>
        </Link>
        }
      </div>
      <div css={css`
        width: 348px;
        height: 20px;
        background-color: #F5F5F5;
        border-radius: 4px;
        margin-top: 24px;
      `}>
        <div css={css`
          background-color: ${progressColor};
          border-radius: 4px;
          width: ${Math.min(1.0, goalPortionMet) * 100}%;
          height: 100%;
          transition: width 0.3s ease;
        `}/>
      </div>
      <div css={css`
        letter-spacing: 0.5px;
        color: #626262;
        margin-top: 24px;
        max-width: 420px;
        ${goalMet && css`color: ${COMPLETE_COLOR}; font-weight: bold; filter: brightness(0.9);`}
      `}>
        <span css={css`font-weight: 800;`}>{hoursBookedTowardsGoal}/{targetHours}</span> hours booked or completed
        {weeklySpecificGoal ? 
        <><br/>
        towards <b>{weeklySpecificGoal}</b>
        <br/>
        ({hoursBookedTotal} total hours in Flow Club)
        </> : null}{goalMet ? '!' : ''}
      </div>
    </div>
  )
}

export const ProgressCircle = ({ mousedOverActive, portionFilled = 0, color, children }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  useEffect(() => {
    if (mousedOverActive) {
      sendSegmentEvent("Progress Circle Moused Over")
    }
  }, [mousedOverActive])

  const diameter = 40;
  const ringWidth = 6;
  const circumference = Math.PI * diameter
  const progressColor = color || (portionFilled < 1 ? INCOMPLETE_COLOR : COMPLETE_COLOR)

  return (
    <div css={css`
      width: ${diameter + ringWidth}px;
      height: ${diameter + ringWidth}px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}>
      <div css={css`
        width: ${diameter}px;
        height: ${diameter}px;
        position: relative;
      `}>
        <div css={css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
          <div css={css`
            font-size: 12px;
            letter-spacing: 0.4px;
            color: #404040;
          `}>
            {children}
          </div>
        </div>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference};
            stroke-dashoffset: ${circumference - (circumference * portionFilled)};
            stroke: ${progressColor};
            stroke-linecap: butt;
            transition: stroke-dashoffset 0.3s ease;
          `}/>
        </svg>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference} ${circumference * portionFilled};
            stroke-dashoffset: ${circumference};
            stroke: #F5F5F5;
            stroke-linecap: butt;
          `}/>
        </svg>
      </div>
    </div>
  )
}
